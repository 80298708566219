import React from 'react'
import Testosterone from './testosterone'
import BloodFlow from './blood-flow'
import WeightLoss from './weight-loss'
import cx from 'classnames'

export default ({ parallax }) => {
  if (!parallax || parallax.isHide) return null

  if (parallax.productType === 'testosterone') {
    return <Testosterone parallax={parallax} />
  }

  if (parallax.productType === 'bloodFlow') {
    return <BloodFlow parallax={parallax} />
  }

  if (parallax.productType === 'weightLoss') {
    return <WeightLoss parallax={parallax} />
  }

  return null
}
