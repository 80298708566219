import React from 'react'
import cx from 'classnames'
import { srcSetProps, urlWithSearchParamsHandler, prepareParagraph, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ buildingBlocks }) => {
  if (!buildingBlocks || buildingBlocks.isHide) return null

  return (
    <div className={styles.buildingBlocks}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={cx(styles.bgs, styles[buildingBlocks.option])}>
            <div className={cx(styles.bg, styles.bgDesktop)} />
            <div className={cx(styles.bg, styles.bgTablet)} />
            <div className={cx(styles.bg, styles.bgMobile)} />
          </div>

          <div className={styles.text}>
            <div className={styles.box}>
              <p className={styles.titleSmall}>{buildingBlocks.smallTitle}</p>
              <h2 className={styles.title}>{buildingBlocks.bigTitle}</h2>

              <div className={styles.table}>
                <div className={styles.row}>
                  <div className={styles.c1}>
                    <p>
                      {buildingBlocks.kingTitle}
                      <span>{buildingBlocks.kingInfo}</span>
                    </p>
                  </div>
                  <div className={styles.c2}>
                    <p>{buildingBlocks.kingPrice}</p>
                  </div>
                </div>
                <div className={cx(styles.row, styles.rowBb)}>
                  <div className={styles.c1}>
                    <p>
                      {buildingBlocks.bbTitle}
                      <span>{buildingBlocks.bbInfo}</span>
                    </p>
                  </div>
                  <div className={styles.c2}>
                    <p>{buildingBlocks.bbPrice}</p>
                  </div>
                </div>
              </div>

              <div className={styles.controls}>
                <a href={buildingBlocks.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
                  {buildingBlocks.buttonText}
                </a>
                <p className={styles.availability}>
                  <span>{buildingBlocks.availabilityText}</span>
                </p>
              </div>
            </div>

            <div className={styles.info}>
              <p className={styles.info1}>{prepareParagraph(buildingBlocks.description1)}</p>
              <p className={styles.info2}>{prepareParagraph(buildingBlocks.description2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
