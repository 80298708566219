import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { prepareParagraph, sortComponents } from '../utils/format'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/Pdp3Page/Info'
import Science from '../components/Pdp3Page/Science'
import Benefits from '../components/Pdp3Page/Benefits'
import BenefitsLine from '../components/Pdp3Page/BenefitsLine'
import Table from '../components/Pdp3Page/Table'
import BenefitsScience from '../components/Pdp3Page/BenefitsScience'
import Parallax from '../components/Pdp3Page/Parallax'
import Results from '../components/Pdp3Page/Results'
import Customers from '../components/Pdp3Page/Customers'
import Reviews from '../components/Pdp3Page/Reviews'
import BloodFlow from '../components/Pdp3Page/BloodFlow'
import Expanding from '../components/Pdp3Page/Expanding'
import Faq from '../components/Pdp3Page/Faq'
import HowItWorks from '../components/Pdp3Page/HowItWorks'
import BuildingBlocks from '../components/Pdp3Page/BuildingBlocks'
import References from '../components/Pdp3Page/References'
import Wim from '../components/Pdp3Page/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Science science={data.science} />,
      priority: data.science?.priority || 99,
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99,
    },
    {
      render: <BenefitsLine benefitsLine={data.benefitsLine} />,
      priority: data.benefitsLine?.priority || 99,
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99,
    },
    {
      render: <BenefitsScience benefitsScience={data.benefitsScience} />,
      priority: data.benefitsScience?.priority || 99,
    },
    {
      render: <Parallax parallax={data.parallax} />,
      priority: data.parallax?.priority || 99,
    },
    {
      render: <Results results={data.results} />,
      priority: data.results?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Expanding expanding={data.expanding} />,
      priority: data.expanding?.priority || 99,
    },    
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <BloodFlow bloodFlow={data.bloodFlow} />,
      priority: data.bloodFlow?.priority || 99,
    },    
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.bb} />,
      priority: data.bb?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout
      data={data}
      signUpUrl={data.info?.buttonUrl}
      signUpText={data.info?.buttonText}
      headerDisable={data.custom?.isHideHeader}
    >
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Info info={data.info} headerDisable={data.custom?.isHideHeader} />
      {components.map(component => component.render)}
    </Layout>
  )
}
