import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ science }) => {
  if (!science || science.isHide) return null

  return (
    <div className={styles.science}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{science.title}</h2>
          {science.linkUrl && science.linkText && (
            <a href={science.linkUrl} target="_blank" className={styles.link}>{science.linkText}</a>
          )}
        </div>

        <div className={styles.info}>
          <table className={styles.infoList}>
            <thead className={styles.ingredientTitle}>
              <tr>
                <td>
                  <p className={styles.infoTitle}>{science.ingredients.title1}</p>
                </td>
                <td>
                  <p className={styles.infoTitle}>{science.ingredients.title2}</p>
                </td>
                {science.ingredients?.title3 && (
                  <td>
                    <p className={styles.infoTitle}>{science.ingredients.title3}</p>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              {science.ingredients?.items &&
                science.ingredients.items.map((ingredient, index) => (
                  <tr key={ingredient._key}>
                    <td>
                      <p className={styles.key}>{prepareParagraph(ingredient.value1)}</p>
                      <img {...srcSetProps(sanityImageUrl(ingredient.image))} alt={ingredient.image?.caption} />
                    </td>
                    <td>
                      {ingredient.value2List && (
                        <ul className={styles.descriptionList}>
                          {ingredient.value2List.map((item, index2) => (
                            <li key={index2}>{prepareParagraph(item)}</li>
                          ))}
                        </ul>
                      )}

                      {ingredient.value2 && <p className={styles.description}>{prepareParagraph(ingredient.value2)}</p>}
                    </td>
                    {science.ingredients?.title3 && (
                      <td>
                        {ingredient.value3List && (
                          <ul className={styles.descriptionList}>
                            {ingredient.value3List.map((item, index2) => (
                              <li key={index2}>{prepareParagraph(item)}</li>
                            ))}
                          </ul>
                        )}

                        {ingredient.value3 && (
                          <p className={styles.description}>{prepareParagraph(ingredient.value3)}</p>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          <div className={styles.infoDrugs}>
            <div className={styles.infoBox}>
              <p className={styles.infoDrugsTitle}>{science.drugFacts?.title}</p>
              {science.drugFacts?.items &&
                science.drugFacts.items.map(row => (
                  <div className={styles.supplement} key={row._key}>
                    <p className={styles.supplementTitle}>{row.title}</p>
                    <p className={styles.supplementValue}>{row.value}</p>
                  </div>
                ))}
              <p className={styles.offLabels}>{prepareParagraph(science.offLabels)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
