import React, { useState, useEffect } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, prepareParagraph, sanityImageUrl } from '../../../utils/format'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link, navigate } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

const MainImage = ({ info, activeImage, changeImage, sliderSettings }) => (
  <div>
    <Swiper {...sliderSettings}>
      {info.images &&
        info.images.map(image => (
          <SwiperSlide className={styles.sliderItem}>
            <img
              className={styles.sliderItemImg}
              {...srcSetProps(sanityImageUrl(image.image))}
              alt={image.image?.caption}
            />
          </SwiperSlide>
        ))}
    </Swiper>

    <div className={styles.imageWrap}>
      <div className={styles.images}>
        {info.images &&
          info.images.map(image => (
            <div
              className={cx(styles.imagesItem, {
                [styles.imagesItemActive]: activeImage === image,
              })}
              key={image._key}
              onClick={() => changeImage(image)}
            >
              <img
                className={styles.imagesItemSrc}
                {...srcSetProps(sanityImageUrl(image.image))}
                alt={image.image?.caption}
              />
            </div>
          ))}
      </div>
      <div className={styles.imageBox}>
        <img
          className={styles.imageSrc}
          {...srcSetProps(sanityImageUrl(activeImage?.image))}
          alt={activeImage?.caption}
        />
      </div>
    </div>
  </div>
)

export default ({ info, headerDisable }) => {
  if (!info) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
  }

  const [subscriptionActive, setSubscriptionActive] = useState(0)
  const [activeImage, setActiveImage] = useState(info.images?.[0])
  const [safetyModalShow, setSafetyModalShow] = useState(false)
  const changeImage = image => {
    setActiveImage(image)
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const reviewsClickHandler = (path) => {
    if (path === '#reviews') {
      const reviewsElement = document.getElementById('reviews')
      if (reviewsElement) {
        gsap.to(window, {
          duration: 2,
          scrollTo: document.getElementById('reviews')
        })
      }
    } else {
      navigate(path)
    }
  }

  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div
      className={cx(styles.info, {
        [styles.infoWhite]: info.isWhite,
        [styles.infoNoHeader]: headerDisable
      })}
    >
      <ul className={styles.breadcrumbs}>
        {info.breadcrumbs &&
          info.breadcrumbs.map((item, index) => (
            <li key={item._key}>
              <Link className={styles.breadcrumbsLink} to={item.url}>
                {item.text}
              </Link>
            </li>
          ))}
      </ul>

      <div className={styles.wrap}>
        <div className={styles.image}>
          {activeImage && (
            <MainImage
              info={info}
              activeImage={activeImage}
              changeImage={changeImage}
              sliderSettings={sliderSettings}
            />
          )}
        </div>

        <div className={styles.content}>
          {info.rating?.url && (
            <div className={styles.rating} onClick={() => reviewsClickHandler(info.rating.url)}>
              {info.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(info.rating.starsImage)} alt={info.rating?.text}/>}
              {info.rating.text && <p className={styles.ratingText}>{info.rating.text}</p>}
            </div>
          )}  

          <h1 className={styles.title}>{info.title}</h1>

          <div className={styles.details}>
            <p className={(styles.description, styles.descriptionDesktop)}>{info.description}</p>
            <p className={(styles.description, styles.descriptionMobile)}>{info.descriptionMobile}</p>

            {info.subscriptionPlanList && info.subscriptionPlanOptions ? (
              <div className={styles.subscription}>
                <div className={styles.subscriptionRow}>
                  <p className={styles.subscriptionTitle}>{info.subscriptionPlanTitle}</p>
                  <div className={styles.subscriptionBox}>
                    {info.subscriptionPlanOptions.map((plan, index) => (
                      <div 
                        className={cx(styles.subItemCol, styles.subItem, {
                          [styles.subItemColActive]: index == subscriptionActive
                        })} 
                        key={plan._key}
                        onClick={() => setSubscriptionActive(index)}
                      >
                        <div className={styles.subItemRow}>
                          <p className={styles.subItemPlan} dangerouslySetInnerHTML={{ __html: plan.monthCount }} />
                          {plan.oldPrice && <div className={styles.subItemOld}>{plan.oldPrice}</div>}
                        </div>
                        <div className={styles.subItemPrice} dangerouslySetInnerHTML={{ __html: plan.newPrice }} />
                      </div>
                    ))}
                  </div>

                  {info.subscriptionPlanLowest && (
                    <p className={styles.subscriptionLowest}>{prepareParagraph(info.subscriptionPlanLowest)}</p>
                  )}
                </div>

                <div className={styles.subscriptionRow}>
                  {info.prices &&
                    info.prices.map((price, index) => (
                      <>
                        <p className={styles.subscriptionTitle}>{price.title}</p>
                        <div className={cx(styles.subItemCol, styles.subItem, styles.subItemNo, styles.subItemColActive)}>
                          <p className={styles.subItemPlan} dangerouslySetInnerHTML={{ __html: price.information }} />
                          <div className={styles.subItemPrice} dangerouslySetInnerHTML={{ __html: price.price }} />
                        </div>
                      </>
                    ))}
                </div>
              </div>
            ) : (
              <ul className={styles.prices}>
                {info.prices &&
                  info.prices.map((price, index) => (
                    <li className={styles.pricesItem} key={price._key}>
                      <div className={styles.pricesRight}>{price.price}</div>
                      <div className={styles.pricesLeft}>
                        <p className={styles.pricesInfo}>{price.information}</p>
                      </div>
                    </li>
                  ))}
              </ul>
            )}

            <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {info.buttonText}
            </a>

            <ul className={styles.benefits}>
              {info.benefits &&
                info.benefits.map((benefit, index) => (
                  <li key={benefit._key}>
                    <img src={sanityImageUrl(benefit.image)} alt={benefit.image?.caption} />
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>

            {info.importantSafetyModal?.modalText && (
              <>
                <div className={styles.safetyModalLink} onClick={openSafetyModalHandler}>ⓘ <span>{info.importantSafetyModal.modalText}</span></div>
                {safetyModalShow && (
                  <div className={styles.safetyModal}>
                    <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
                    <div className={styles.safetyModalWrap}>
                      <div className={styles.safetyModalBox}>
                        <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
                        <p className={styles.safetyModalTitle}>{info.importantSafetyModal.modalTitle}</p>
                        <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: info.importantSafetyModal.modalContent }} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
