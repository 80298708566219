import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ benefitsScience }) => {
  if (!benefitsScience || benefitsScience.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.wrap}>
        <p className={styles.title}>{benefitsScience.title}</p>

        <div className={styles.items}>
          <img
            {...srcSetProps(sanityImageUrl(benefitsScience.image))}
            className={styles.background}
            alt={benefitsScience.image?.caption}
          />
          <div className={styles.topLeft}>
            <p className={styles.header}>{benefitsScience.topLeftTitle}</p>
            <p className={styles.text}>{prepareParagraph(benefitsScience.topLeftText)}</p>
          </div>
          <div className={styles.topRight}>
            <p className={styles.header}>{benefitsScience.topRightTitle}</p>
            <p className={styles.text}>{prepareParagraph(benefitsScience.topRightText)}</p>
          </div>
          <div className={styles.bottomLeft}>
            <p className={styles.header}>{benefitsScience.bottomLeftTitle}</p>
            <p className={styles.text}>{prepareParagraph(benefitsScience.bottomLeftText)}</p>
          </div>
          <div className={styles.bottomRight}>
            <p className={styles.header}>{benefitsScience.bottomRightTitle}</p>
            <p className={styles.text}>{prepareParagraph(benefitsScience.bottomRightText)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
