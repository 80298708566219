import React from 'react'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ results }) => {
  if (!results || results.isHide) return null

  const sliderSettings = {
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    dotsClass: styles.dots,
  }

  return (
    <div className={styles.results}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <p className={styles.titleSmall}>{results.titleSmall}</p>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: results.titleBig }} />
        </div>

        <div className={styles.slider}>
          <div className={styles.sliderWrap}>
            <Slider {...sliderSettings}>
              {results.items &&
                results.items.map((item, index) => (
                  <div
                    className={cx(styles.sliderItem, { [styles.sliderItemActive]: index === 0 })}
                    key={item._key}
                    data-index={index}
                  >
                    <div className={styles.inside}>
                      <div className={styles.top}>
                        <div className={styles.image}>
                          <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.name} />
                        </div>
                        <div className={styles.about}>
                          <p className={styles.name}>
                            {item.name}
                            <span>{item.time}</span>
                          </p>
                          <p className={styles.review} dangerouslySetInnerHTML={{ __html: item.review }} />
                        </div>
                      </div>
                      <div className={styles.bottom}>
                        <div className={styles.row}>
                          <div className={cx(styles.c1, styles.bold)}>{item.results}</div>
                          <div className={cx(styles.c2, styles.multiplier)}>{item.multiplier1}</div>
                          <div className={cx(styles.c3, styles.multiplier)}>{item.multiplier2}</div>
                          <div className={cx(styles.c4, styles.multiplier)}>{item.multiplier3}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.c1}></div>
                          <div className={cx(styles.c2, styles.bold)}>{item.title1}</div>
                          <div className={cx(styles.c3, styles.bold)}>{item.title2}</div>
                          <div className={cx(styles.c4, styles.bold)}>{item.title3}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={cx(styles.c1, styles.bold)}>{item.resultsBefore}</div>
                          <div className={cx(styles.c2, styles.result)}>{item.resultsBefore1}</div>
                          <div className={cx(styles.c3, styles.result)}>{item.resultsBefore2}</div>
                          <div className={cx(styles.c4, styles.result)}>{item.resultsBefore3}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={cx(styles.c1, styles.bold)}>{item.resultsAfter}</div>
                          <div className={cx(styles.c2, styles.result)}>{item.resultsAfter1}</div>
                          <div className={cx(styles.c3, styles.result)}>{item.resultsAfter2}</div>
                          <div className={cx(styles.c4, styles.result)}>{item.resultsAfter3}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          <p className={styles.extra}>{results.info}</p>
        </div>
      </div>
    </div>
  )
}
