import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ benefitsLine }) => {
  if (!benefitsLine || benefitsLine.isHide) return null

  return (
    <div className={styles.benefitsLine}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{benefitsLine.title}</h2>

        <div className={styles.box}>
          {benefitsLine.items &&
            benefitsLine.items.map((item, index) => (
              <div className={styles.item} key={item._key}>
                <img {...srcSetProps(sanityImageUrl(item.image))} className={styles.image} alt={item.image?.caption} />
                <p className={styles.key}>{item.title}</p>
                <p className={styles.info}>{prepareParagraph(item.description)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
