import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { gsap } from 'gsap'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ faq, buttonColors = 'default', productVersion = false }) => {
  if (!faq || faq.isHide) return null

  const [activeIndex, setActiveIndex] = useState(false)

  const clickHandler = index => {
    const faqElementOld = document.querySelector(`.${styles.itemText}[data-index="${activeIndex}"]`)
    const faqElementNew = document.querySelector(`.${styles.itemText}[data-index="${index}"]`)

    if (activeIndex === index) {
      gsap.to(faqElementOld, { height: '0', duration: 0.3 })
      setActiveIndex(false)
    } else {
      gsap.to(faqElementOld, { height: '0', duration: 0.3 })
      gsap.to(faqElementNew, { height: 'auto', duration: 0.3 })
      setActiveIndex(index)
    }
  }

  useEffect(() => {
    const hash = location.hash
    const faqKey = '#faq-section_'
    if (hash.includes(faqKey)) {
      clickHandler(parseInt(hash.replace(faqKey, '')))
    }

    clickHandler(0)
  }, [])

  const connectedList = faq.faqList.title ? true : false
  const title = connectedList ? faq.faqList.title : faq.title
  const items = connectedList ? faq.faqList.items : faq.items

  return (
    <div className={styles.faq}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
          {connectedList ? (
            <p>{prepareParagraph(faq.faqList.description)}</p>
          ) : (
            <a href={faq.supportUrl} target="_blank" className={styles.support}>
              {faq.supportText}
            </a>
          )}
        </div>
        <div className={styles.right}>
          {items &&
            items.map((item, index) => (
              <div
                className={cx(styles.item, {
                  [styles.itemShow]: activeIndex === index,
                })}
                key={item._key}
              >
                <div className={styles.itemAnchor} id={`faq-section_${index}`} />
                <h3 className={styles.itemTitle} onClick={() => clickHandler(index)}>
                  {item.title}
                </h3>
                <p className={styles.itemText} data-index={index}>
                  {prepareParagraph(item.text)}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
